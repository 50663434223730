
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import VideoPlayer from "@/components/VideoPlayer.vue";
import IntroSlides from "@/components/slides/IntroSlides.vue";
import { ISession } from "@/schemas/ISession";
import { apiUrl } from "@/env";
import { IActivity } from "@/schemas/IActivity";
import clientCache from "@/utils/cacheUtils";
import { mapState } from "vuex";

@Component({
  name: "TaskDetailPage",
  components: { IntroSlides, VideoPlayer },
  computed: {
    ...mapState("session", ["session"]),
  },
})
export default class TaskDetailPage extends Vue {
  // The current session identifier
  @Prop({ required: true, type: String })
  sessionId!: string;

  // The current session object
  session: ISession | undefined;
  // Boolean tells if slides must be displayed or not
  displaySlides = false;
  // Boolean tells if video must be displayed or not
  displayVideo = false;
  // Boolean tells if redirect button must be displayed or not
  displayRedirect = false;

  created(): void {
    if (!this.session) {
      this.$store.dispatch("session/fetchSession", {
        sessionId: this.sessionId,
        next: (session: ISession) => {
          this.session = session;
          this.initiateDisplays();
        },
      });
      return;
    }
    this.initiateDisplays();
  }

  initiateDisplays(): void {
    this.displayVideo ||=
      parseInt(
        clientCache.get(`${this.sessionId}:${this.activity.id}:video`)
      ) !== 0 && !!this.activity?.video;
    this.displaySlides ||=
      parseInt(
        clientCache.get(`${this.sessionId}:${this.activity.id}:slides`)
      ) !== 0 &&
      !this.displayVideo &&
      !!this.activity &&
      !!this.activity.slides.length;

    this.displayRedirect ||= !this.displayVideo && !this.displaySlides;
  }

  get activity(): IActivity | undefined {
    return this.session?.activity;
  }

  get videoUrl(): string {
    return this.activity
      ? `${apiUrl}/video/${this.activity.id}/${this.activity?.video}`
      : "";
  }

  redirectToSingleSession(): void {
    this.displayVideo ??= false;
    if (this.activity)
      clientCache.cache(`${this.sessionId}:${this.activity.id}:video`, 0);
    this.displaySlides ??= false;
    if (this.activity)
      clientCache.cache(`${this.sessionId}:${this.activity.id}:slides`, 0);
    this.$router.push({
      name: "singleSession",
      params: {
        sessionId: this.sessionId,
      },
    });
  }
}
