
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "VideoPlayer" })
export default class VideoPlayer extends Vue {
  @Prop({ required: true, type: String })
  assetURL!: string;

  created(): void {
    // this.getLocalStream();
  }
  // getLocalStream() {
  // navigator.mediaDevices
  //   .getUserMedia({ video: false, audio: true })
  //   .then((stream) => {
  //     window.localStream = stream; // A
  //     window.localAudio.srcObject = stream; // B
  //     window.localAudio.autoplay = true; // C
  //   })
  //   .catch((err) => {
  //     console.error(`you got an error: ${err}`);
  //   });
  // }
}
